import { ContentType, Method } from "axios-mapper";
import https from "../utils/https";

export const getMembers = (members) => {
  return https().request("/member", Method.GET, members, ContentType.form);
};

export const getMemberLoginRecords = (memberId, start, end, current, size) => {
  return https().request("/member/login-record", Method.GET, { memberId: memberId, start: start, end: end, current: current, size: size }, ContentType.form);
};

export const getMemberDetails = (id) => {
  return https().request(`/member/detail/${id}`, Method.GET, {}, ContentType.form);
};

export const freezeMember = (id) => {
  return https().request(`/member/${id}/freeze?_method=PUT`, Method.POST, { id: id });
};

export const unfreezeMember = (id) => {
  return https().request(`/member/${id}/unfreeze?_method=PUT`, Method.POST, { id: id });
};

export const getMemberFriends = (id, query) => {
  return https().request(`/member/friends/${id}`, Method.GET, query, ContentType.form);
};

export const getMemberGroups = (id, query) => {
  return https().request(`/member/groups/${id}`, Method.GET, query, ContentType.form);
};

export const updatePassword = (id, password) => {
  return https().request(`/member/${id}/password?_method=PUT`, Method.POST, { password: password }, ContentType.form);
};

export const updateLoginName = (id, loginName) => {
  return https().request(`/member/${id}/loginName?_method=PUT`, Method.POST, { loginName: loginName }, ContentType.form);
};

export const createMember = (member) => {
  return https().request(`/member/create`, Method.POST, member, ContentType.form);
};
