<template>
  <div class="tables-container-wrap1">
    <el-dialog
      :title="uiControl.dialogTitle"
      v-model="uiControl.dialogVisible"
      append-to-body
      width="450px"
    >
      <el-form
        ref="roleForm"
        :rules="formRules"
        :model="form"
        :inline="true"
        size="medium"
        label-width="150px"
      >
        <el-form-item label="Member" prop="name">
          <el-input
            :disabled="true"
            v-model="form.name"
            style="width: 200px"
          />
        </el-form-item>
        <el-form-item label="Role">
          <el-select
            v-model="form.role"
            placeholder="Role"
            style="width: 200px"
          >
            <el-option
              v-for="item in uiControl.role"
              :key="item.value"
              :label="item.displayName"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <div class="dialog-footer">
          <el-button @click="uiControl.dialogVisible = false">cancel</el-button>
          <el-button type="primary" @click="updateMemberRole">confirm</el-button>
        </div>
      </el-form>
    </el-dialog>
    <el-card class="info-card">
      <template #header>
        <div class="clearfix">
          <span class="role-span">Group Information</span>
        </div>
      </template>
      <el-descriptions
        size="small"
        class="margin-top"
        :column="3"
        border
        v-loading="loading.groupInfo"
      >
        <el-descriptions-item label-align="left" label-class-name="member-label" class-name="member-context">
          <template #label>
            <div>
              <svg-icon icon-class="peoples" style="height: 16px;width: 16px;" />
              Name
            </div>
          </template>
          <span v-if="groupDetail.name !== null">{{ groupDetail.name }}</span>
          <span v-if="groupDetail.name === null">-</span>
        </el-descriptions-item>
        <el-descriptions-item label-align="left" label-class-name="member-label" class-name="member-context">
          <template #label>
            <div>
              <svg-icon icon-class="setting" style="height: 16px;width: 16px;" />
              Speak Mode
            </div>
          </template>
          <el-radio-group :model-value="groupDetail.speakMode" size="mini">
            <el-radio
              v-for="item in permission"
              :key="item.value"
              :label="item.value"
              @change="updatePermissionProperty(updatePermission.speakMode, groupDetail.id, item.value)"
            >{{ item.label }}</el-radio>
          </el-radio-group>
        </el-descriptions-item>
        <el-descriptions-item label-align="left" label-class-name="member-label" class-name="member-context">
          <template #label>
            <div>
              <svg-icon icon-class="setting" style="height: 16px;width: 16px;" />
              Private Chat
            </div>
          </template>
          <el-radio-group :model-value="groupDetail.privatelyChat" size="mini">
            <el-radio
              v-for="item in permission"
              :key="item.value"
              :label="item.value"
              @change="updatePermissionProperty(updatePermission.privatelyChat, groupDetail.id, item.value)"
            >{{ item.label }}</el-radio>
          </el-radio-group>
        </el-descriptions-item>
        <el-descriptions-item label-align="left" label-class-name="member-label" class-name="member-context">
          <template #label>
            <div>
              <svg-icon icon-class="setting" style="height: 16px;width: 16px;" />
              Share QR Code
            </div>
          </template>
          <el-radio-group :model-value="groupDetail.shareQrcode" size="mini">
            <el-radio
              v-for="item in permission"
              :key="item.value"
              :label="item.value"
              @change="updatePermissionProperty(updatePermission.inviteUsers, groupDetail.id, item.value)"
            >{{ item.label }}</el-radio>
          </el-radio-group>
        </el-descriptions-item>
        <el-descriptions-item label-align="left" label-class-name="member-label" class-name="member-context">
          <template #label>
            <div>
              <svg-icon icon-class="setting" style="height: 16px;width: 16px;" />
              Add Users
            </div>
          </template>
          <el-radio-group :model-value="groupDetail.addUsers" size="mini">
            <el-radio
              v-for="item in permission"
              :key="item.value"
              :label="item.value"
              @change="updatePermissionProperty(updatePermission.addUsers, groupDetail.id, item.value)"
            >{{ item.label }}</el-radio>
          </el-radio-group>
        </el-descriptions-item>
        <el-descriptions-item label-align="left" label-class-name="member-label" class-name="member-context">
          <template #label>
            <div>
              <svg-icon icon-class="lock" style="height: 16px;width: 16px;" />
              Status
            </div>
          </template>
          <el-tag v-if="groupDetail.status === 'NORMAL'" size="mini" type="success">
            {{ groupDetail.status }}
          </el-tag>
          <el-tag v-if="groupDetail.status === 'FROZEN'" size="mini" type="danger">
            {{ groupDetail.status }}
          </el-tag>
          <el-tag v-if="groupDetail.status === null" size="mini" type="info">
            -
          </el-tag>
          <el-button v-if="groupDetail.status === 'NORMAL'" type="info" size="mini" style="float: right;"
                     v-permission="['sys:group:update:state']" @click="freeze(groupDetail.id)"
          >
            Freeze
          </el-button>
          <el-button v-if="groupDetail.status === 'FROZEN'" type="info" size="mini" style="float: right;"
                     v-permission="['sys:group:update:state']" @click="unfreeze(groupDetail.id)"
          >
            Open
          </el-button>
        </el-descriptions-item>
        <el-descriptions-item label-align="left" label-class-name="member-label" class-name="member-context">
          <template #label>
            <div>
              <svg-icon icon-class="banner" style="height: 16px;width: 16px;" />
              Banner
            </div>
          </template>
          <span v-if="groupDetail.banner !== null">{{ groupDetail.banner }}</span>
          <span v-if="groupDetail.banner === null">-</span>
        </el-descriptions-item>
      </el-descriptions>
    </el-card>

    <el-card class="info-card">
      <template #header>
        <div class="clearfix">
          <span class="role-span">Group Members</span>
          <el-button class="el-button el-button--primary el-button--mini" style="margin-left:10px;"
                     @click="addFakeMembers"
                     v-permission="['sys:group-member:add']"
          >
            Add Fake Member
          </el-button>
        </div>
      </template>
      <el-table :data="page.records" ref="table"
                v-loading="loading.members"
                row-key="id"
                size="small"
                highlight-current-row
      >
        <el-table-column prop="loginName" label="Login Name" :min-width="20">
          <template #default="scope">
            <router-link :to="`/member/details/${scope.row.memberId}`">
              <el-link type="primary">{{ scope.row.loginName }}</el-link>
            </router-link>
          </template>
        </el-table-column>
        <el-table-column prop="nickName" label="Nick Name" :min-width="20" />
        <el-table-column prop="type" label="Member Type" :min-width="20">
          <template #default="scope">
            <el-tag v-if="scope.row.type === 'USER'" type="success" size="mini">{{ scope.row.type }}</el-tag>
            <el-tag v-if="scope.row.type === 'PARTNER'" size="mini">{{ scope.row.type }}</el-tag>
            <el-tag v-if="scope.row.type === 'GUEST'" type="info" size="mini">{{ scope.row.type }}</el-tag>
            <el-tag v-if="scope.row.type === 'ROBOT'" size="mini">{{ scope.row.type }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="Status" :min-width="20">
          <template #default="scope">
            <el-tag v-if="scope.row.status === 'NORMAL'" type="success" size="mini">{{ scope.row.status }}</el-tag>
            <el-tag v-if="scope.row.status === 'FROZEN'" type="danger" size="mini">{{ scope.row.status }}</el-tag>
            <el-tag v-if="scope.row.status === null" type="info" size="mini">-</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="role" label="Role" :min-width="20" />
        <el-table-column prop="regTime" label="Reg Time" :min-width="20" />
        <el-table-column prop="lastLoginTime" label="Last Login" :min-width="20" />
        <el-table-column label="operate" align="center" :min-width="20">
          <template #default="scope">
            <el-button v-permission="['sys:group-member:kickout']"
                       :disabled="scope.row.role === 'OWNER'"
                       icon="el-icon-remove"
                       size="mini"
                       type="danger"
                       @click="kickoutMember(scope.row.memberId)"
            />
            <el-button v-permission="['sys:group-member:edit']"
                       :disabled="scope.row.role === 'OWNER'"
                       icon="el-icon-user"
                       size="mini"
                       type="primary"
                       @click="showEditRole(scope.row)"
            />
          </template>
        </el-table-column>
      </el-table>
      <el-pagination class="pagination"
                     @current-change="changePage"
                     layout="prev, pager, next"
                     :page-size="request.size"
                     :page-count="page.pages"
                     :current-page="request.current"
                     style="float: left;"
      />
    </el-card>
  </div>
</template>

<script>

import { defineComponent, nextTick, onMounted, reactive, ref } from "vue";
import { ElMessage, ElMessageBox } from "element-plus";
import {
  getGroupDetails,
  freezeGroup,
  unfreezeGroup,
  getGroupMembers,
  updateGroupInviteUsers,
  updateGroupSpeakMode,
  updateGroupPrivatelyChat,
  updateGroupAddUsers,
  kickoutGroupMember,
  updateGroupMemberRole,
  addFakeUsersToGroup
} from "../../../../../../api/group";
import { required } from "@/utils/validate";

export default defineComponent({
  props: {
    mbrId: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const uiControl = reactive({
      dialogVisible: false,
      dialogTitle: "",
      role: [
        { displayName: "Owner", value: "OWNER" },
        { displayName: "Admin", value: "ADMIN" },
        { displayName: "Member", value: "MEMBER" },
      ],
    })

    const roleForm = ref(null)
    const form = reactive({
      id: null,
      name: null,
      role: null,
    })

    const formRules = reactive({
      id: [required('Member id is required')],
      role: [required('Role is required')],
    })

    const loading = reactive({
      groupInfo: false,
      members: false
    });

    const groupDetail = reactive({
      id: 0,
      name: "",
      speakMode: "",
      shareQrcode: "",
      addUsers: "",
      status: "",
      banner: "",
    });

    const permission = reactive([
      { label: "All", value: "ALL" },
      { label: "Owner/Admin", value: "OWNER_ADMIN" },
      { label: "Owner", value: "OWNER" }
    ]);

    const updatePermission = reactive({
      speakMode: updateGroupSpeakMode,
      inviteUsers: updateGroupInviteUsers,
      addUsers: updateGroupAddUsers,
      privatelyChat: updateGroupPrivatelyChat
    })

    const page = reactive({
      pages: 0,
      records: []
    });

    const request = reactive({
      size: 10,
      current: 1
    });

    const freeze = (id) => {
      ElMessageBox.confirm(
        "Confirm freeze group?",
        {
          confirmButtonText: "Confirm",
          cancelButtonText: "Cancel",
          type: "warning"
        }
      ).then(async () => {
        await freezeGroup(id);
        const data = await getGroupDetails(props.mbrId);
        Object.keys({ ...data.data }).forEach(detailField => {
          groupDetail[detailField] = data.data[detailField];
        });
        ElMessage({ message: "Freeze success", type: "success" });
      }).catch(() => {});
    };

    const unfreeze = (id) => {
      ElMessageBox.confirm(
        "Confirm Unfreeze group?",
        {
          confirmButtonText: "Confirm",
          cancelButtonText: "Cancel",
          type: "warning"
        }
      ).then(async () => {
        await unfreezeGroup(id);
        const data = await getGroupDetails(props.mbrId);
        Object.keys({ ...data.data }).forEach(detailField => {
          groupDetail[detailField] = data.data[detailField];
        });
        ElMessage({ message: "Unfreeze success", type: "success" });
      }).catch(() => {});
    };

    const updatePermissionProperty = (groupProperty, id, permission) => {
      if (id === 0) {
        return;
      }
      ElMessageBox.confirm(
        "Confirm update group setting?",
        {
          confirmButtonText: "Confirm",
          cancelButtonText: "Cancel",
          type: "warning"
        }
      ).then(async () => {
        await groupProperty(id, permission);
        const data = await getGroupDetails(props.mbrId);
        Object.keys({ ...data.data }).forEach(detailField => {
          groupDetail[detailField] = data.data[detailField];
        });
        ElMessage({ message: "Update group setting success", type: "success" });
      }).catch(() => {});
    };

    const loadMembers = async () => {
      loading.members = true;
      const { data: ret } = await getGroupMembers(props.mbrId, request);
      page.pages = ret.pages;
      page.records = ret.records;
      loading.members = false;
    };

    const addFakeMembers = () => {
      ElMessageBox.confirm(
        "Confirm add fake group member?",
        {
          confirmButtonText: "Confirm",
          cancelButtonText: "Cancel",
          type: "warning"
        }
      ).then(async () => {
        const count = Math.floor(Math.random() * (10 - 5 + 1)) + 5;

        await addFakeUsersToGroup(props.mbrId, count);
        loadMembers();
        ElMessage({ message: "Add fake group member success", type: "success" });
      }).catch(() => {});
    };

    const kickoutMember = (memberId) => {
      ElMessageBox.confirm(
        "Confirm kickout group member?",
        {
          confirmButtonText: "Confirm",
          cancelButtonText: "Cancel",
          type: "warning"
        }
      ).then(async () => {
        await kickoutGroupMember(props.mbrId, memberId);
        loadMembers();
        ElMessage({ message: "Kickout group member success", type: "success" });
      }).catch(() => {});
    };

    const changePage = (page) => {
      if (request.current >= 1) {
        request.current = page;
        loadMembers();
      }
    };

    const updateMemberRole = () => {
      ElMessageBox.confirm(
        "Confirm update group member role?",
        {
          confirmButtonText: "Confirm",
          cancelButtonText: "Cancel",
          type: "warning"
        }
      ).then(async () => {
        await updateGroupMemberRole(props.mbrId, form.id, form.role);
        loadMembers();
        ElMessage({ message: "update role success", type: "success" });
      }).catch(() => {});
    };

    function showEditRole(member) {
      uiControl.dialogTitle = 'Edit Member Role'
      uiControl.dialogVisible = true
      nextTick(() => {
        form.id = member.memberId
        form.name = member.loginName
        form.role = member.role
      })
    }

    onMounted(async () => {
      loading.groupInfo = true;
      const data = await getGroupDetails(props.mbrId);
      Object.keys({ ...data.data }).forEach(detailField => {
        groupDetail[detailField] = data.data[detailField];
      });
      loading.groupInfo = false;
      loadMembers();
    });

    return {
      uiControl,
      permission,
      loading,
      groupDetail,
      page,
      request,
      unfreeze,
      freeze,
      updatePermissionProperty,
      changePage,
      loadMembers,
      addFakeMembers,
      kickoutMember,
      updateMemberRole,
      updatePermission,
      form,
      roleForm,
      formRules,
      showEditRole
    };
  }
});

</script>
<style lang="scss" scoped>

.tables-container-wrap1 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .info-card {
    margin-bottom: 10px;
  }
}

.box-card {
  ::v-deep(.el-card__body) {
    padding: 0;
  }
}

::v-deep {
  .el-tabs__content {
    padding: 0;
  }
}

::v-deep([class^="el-table"]) {
  .cell,
  .remove-padding {
    padding: 0 !important;
  }

  .remove-padding {
    .cell {
      display: flex;
    }
  }
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
}

</style>
